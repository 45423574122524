<template>
	<div class="slot-edit-page" v-if="eventSlot">
		<SlotShortSettings v-if="calendarIsDefault || !calendarIsCommercial" 
			:eventSlot="eventSlot"
			:submitHandler="submitHandler"
			:closeHandler="goToCalendar"/>
		<SlotSettings v-else 
			:eventSlot="eventSlot"
			:submitHandler="submitHandler" 
			:closeHandler="goToCalendar"/>
	</div>
</template>

<script>
import YYYYMMDD, { convertTZ } from '../scripts/date';
import Slot from '../scripts/slot';
import CMS from '../service/cms/service';

export default {
	name: 'SlotEdit',

	components: {
		SlotSettings: () => import("../components/forms/SlotSettings.vue"),
		SlotShortSettings: () => import("../components/forms/SlotShortSettings.vue"),
	},

	computed: {
		calendar(){
			return this.$store.getters.currentCalendar()
		},
		calendarUid(){
			if (!this.calendar)
				return undefined
			return this.calendar.uid
		},
		calendarTimezone(){
			if (!this.calendar)
				return undefined
			return this.$store.getters.calendarTimezone
		},
		calendarIsDefault(){
			if (!this.calendar)
				return false
			let defaultUserCalendar = this.$store.getters.getDefaultCalendar
			let currentCalendar = this.calendar.uid
			return defaultUserCalendar == currentCalendar
		},
		calendarIsCommercial(){
			if (!this.calendar)
				return false
			return this.calendar.isCommercial
		},
		browserTimezone(){
            return this.$store.getters.browserTimezone
		},
		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		},
	},

	watch: {
        '$route.params.slotId': {
            handler: function () {
				this.eventSlotIsRequested = false
				let browserTzIsValid = this.browserTimezone && this.browserTimezone.id != -1
				let calendarTzIsValid = this.calendarTimezone && this.calendarTimezone.id != -1
				
				const updater = () => {
					if (browserTzIsValid && calendarTzIsValid) 
						this.updateCurrentSlot()
					else 
						setTimeout(updater, 100)
				}

				updater()
            },
            deep: true,
            immediate: true
        },
	},

	data() {
		return {
			eventSlot: null,	
			eventSlotIsRequested: false,
			prevRoute: null,
		};
	},

    beforeRouteLeave(to, from, next) {
		
		let allowedRedirection = [
			"calendar",
			"calendar-schedule",
			"calendar-settings",
			"calendar-settings-attenders-menu",
			"calendar-settings-host-menu"
		]
		
        if (to.params.calendarUid == from.params.calendarUid && allowedRedirection.includes(to.name)){
			const isRedirToHosts = to.name == "calendar-settings-host-menu"
			const isRedirToAttenders = to.name == "calendar-settings-attenders-menu"
			if (isRedirToAttenders || isRedirToHosts){
				const currentPageData = {
					name: 'slot-edit',
					params: {
						calendarUid: this.calendarUid,
						slotId: this.eventSlot.index
					},
					query: from.query
				}
				if (isRedirToAttenders) {
					localStorage.setItem('originPage-attenders', JSON.stringify(currentPageData))
				} else if (isRedirToHosts) {
					localStorage.setItem('originPage-hosts', JSON.stringify(currentPageData))
				}
			}
			
			next()
        } else {
			this.goToCalendar()
			next()
        }
    },
	created(){
		const updater = () => {
			if (this.calendarTimezone && this.browserTimezone) 
				this.updateCurrentSlot()
			else 
				setTimeout(updater, 100)
		}

		updater()
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
	methods: {
		updateCurrentSlot(){
			const slotIndex = this.$route.params.slotId
			if (this.slotCollection && this.slotCollection.isLoaded) {
				this.eventSlot = this.slotCollection.getById(this.$route.params.slotId)
				if (this.eventSlot)
					return
			}

			if (this.eventSlotIsRequested) 
				return
			this.eventSlotIsRequested = true
            CMS.slots.get(this.calendarUid, slotIndex).then((slotInfo) => {
                if (slotInfo.err) throw slotInfo.err
                let slotDetails = slotInfo.slot

                let date = new Date(slotDetails.startAt)
                let dateInCalendarTZ = convertTZ(date, this.browserTimezone, this.calendarTimezone)
                let dur = slotDetails.dur

                this.eventSlot = new Slot(dateInCalendarTZ, dur, slotDetails)
				this.eventSlot.addAttenders(slotInfo.attenders)
			})
        },
		goToCalendar(){

			localStorage.removeItem(`hosts-${this.eventSlot.index}`)
			localStorage.removeItem(`attenders-${this.eventSlot.index}`)

			if (this.prevRoute && this.prevRoute.name == "calendar-schedule") {
				this.$router.back()
				return
			}

			const originPage = JSON.parse(localStorage.getItem('originPage-edit'))
			if (originPage) {
				console.log('originPage :>> ', originPage);
				this.$router.replace(originPage)
				localStorage.removeItem('originPage-edit')
				return
			}

			this.$router.replace({
				name: "calendar-schedule",
				params: {
					calendarUid: this.calendarUid,
				},
				query: {
					dt: YYYYMMDD(this.eventSlot.period.start.date),
				}
			})
		},
		submitHandler(form){
			if (!form)
				return

            const browserTz = this.browserTimezone
			const calendarTz = this.calendarTimezone

            // 1. Basic validation
            let isValid = form.validate()
            if (!isValid) return 

            // 2. Date validation with difference between timezones
            isValid = form.validateConvertedDate(browserTz)
            if (!isValid) return

            // 3. Get form data to submit
            let submitData = form.toSubmitData()
            let calendarUid = this.$route.params.calendarUid

            CMS.slots.update(calendarUid, this.eventSlot.index, submitData)
            .then( data => {
				if (data.err) {
					throw data.err
				}
				
				const slotData = data.slot
				const attenders = data.attenders
				const users = data.user ? 
					Object.values(data.user) : null
				const host = data.host
				if (this.slotCollection && this.slotCollection.isLoaded){
					if (slotData.regular && !slotData.regular.attendingUserId && users){
						slotData.regular.attendingUserId = users.map(user => user.id)
					}
					
					// Update slot date and another settings
					slotData.startAt = convertTZ(new Date(slotData.startAt), browserTz, calendarTz)
					this.slotCollection.replaceSlot(this.eventSlot, slotData.startAt)
					this.eventSlot.updateSettings(slotData)
					
					// Add new attenders to the slot
					this.eventSlot.addAttenders(attenders)
					
					// Update related with slot collection users
					if (users && users.length > 0) {
						const updatedUsers = users.map(user => {
							return {
								id: user.id,
								name: user.name ? user.name : user.fullName,
							}
						})
						this.slotCollection.users.addAttenders(updatedUsers)
					}
					if (host)
						this.slotCollection.users.addHosts([host])
				}
				

                if (form.isCreateTemplate){
                    this.$store.dispatch('setup-slot-templates')
                }
                this.goToCalendar(this.eventSlot.period.start.date)
            })
			.catch((err) => {console.error(err)})
		}
	},
};
</script>

<style scoped>
.slot-edit-page{
	height: 100dvh;
}
</style>